import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

// export const allDeservants = () => {
//   return axios.get(APP_CONTEXT_URL + "/v1/deservants", {
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

export const getAllDeservants = (field, order, current, pageSize) => {
  return axios.post(
    APP_CONTEXT_URL +
    "/v1/getalldeservants" +
    "?page=" +
    current +
    "&size=" +
    pageSize,
    { field, order },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      },
    }
  );
};

export const addDeservants = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addDeservant", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const updateDeservants = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/updateDeservant", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const deleteDeservants = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deleteDeservants/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getDeservantsForCycle = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getDeservantsForCycle/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};
export const getDeservantsForProgram = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getDeservantsForProgram/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const uploadImage = (deservantId, file) => {
  return axios.post(APP_CONTEXT_URL + `/v1/uploadimage/${deservantId}`, file, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getDeservantCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/deservantcount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const getSearchedDeservants = (text, current, pageSize) => {
  return axios.get(
    APP_CONTEXT_URL +
    "/v1/deservants/" + text +
    "?page=" +
    current +
    "&size=" +
    pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
      },
    }
  );
};

export const getReferrerNames = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/refferernames", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

// export const getFilterDeservants= (list,current,pageSize) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/filterdeservants?page=" +
//   current +
//   "&size=" +
//   pageSize,list ,{
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };
// export const getFilterDeservantsCount= (list) => {
//   return axios.post(APP_CONTEXT_URL + "/v1/filterdeservantscount",list ,{
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
//     },
//   });
// };

export const getDeservantFilter= (filter,current,pageSize) => {
  return axios.post(APP_CONTEXT_URL + "/v1/referredByFilter?page=" +
    current +
    "&size=" +
    pageSize,filter ,{
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};

export const uniqueReferrers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/deservants/uniqueReferrers", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-rashanDistribution_token"),
    },
  });
};