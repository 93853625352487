import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";
import { Sidebar } from "primereact/sidebar";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Input } from "antd";
import {
  addDeservants,
  deleteDeservants,
  uploadImage,
  getDeservantFilter,
  uniqueReferrers,
} from "../../../apis/DeservantApi";
import { getAllLocation } from "../../../apis/LocationApi";
import { allDistributorsList } from "../../../apis/DistributorApi";
import "./Deservant.scss";
import { TabView, TabPanel } from "primereact/tabview";
import DeservantDetails from "./DeservantDetails";
import { Field } from "formik";
const Deservant = (props) => {
  const { Search } = Input;
  const dt = useRef(null);
  const toast = useRef(null);
  const moment = require("moment");
  const ref = useRef();
  const [deservantData, setDeservantAreaData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [deleteDeservantDialog, setDeleteDeservantDialog] = useState(false);
  const [sidebarVisible, setsidebarVisible] = useState(false);
  const [valuesForEdit, setValuesForEdit] = useState();
  const [distributorsList, setDistributorsList] = useState([]);
  const [deleteDeservant, setDeleteDeservant] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [deserventEdit, setDeserventEdit] = useState(false);
  const [id, setId] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalDeservants, setTotalDeservants] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [deservantDialoge, setDeservantDialoge] = useState(false);
  const [selectedDeservant, setSelectedDeservant] = useState({});
  const [locationTable, setLocationTable] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [basicFirst, setBasicFirst] = useState(0);
  const [field, setField] = useState("id");
  const [order, setOrder] = useState("asc");
  const [refferedByFilter, setRefferedByFilter] = useState([]);

  const [referrers, setReferrers] = useState([]);
  const [zakatApplicabls] = useState(["yes", "no"]);
  const [selectedZakat, setSelectedZakat] = useState([]);
  const [multiSortMeta, setMultiSortMeta] = useState([
    { field: "userId.id", order: -1 },
  ]);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    if (ref.current) clearTimeout(ref.current);
    if (e.target.value !== "") {
      setSearchedValue(e.target.value);
    } else {
      setSearchedValue("");
      searchReferrers();
    }
    setGlobalFilterValue(value);
  };

  const searchReferrers = () => {
    setLoading(true);
    uniqueReferrers().then((res) => {
      if (res.status === 200) {
        setReferrers(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    searchReferrers();
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm();

  const loadAreaData = () => {
    getAllLocation().then((res) => {
      if (res.status === 200) {
        setLocationTable(res.data);
        setLoading(false);
      }
    });
  };

  const OnSubmit = (data) => {
    if (isEdit) {
      let dataForApi = {
        id: deserventEdit ? deserventEdit.id : null,
        adultMember: data.adultMember,
        dependentMember: data.dependentMember,
        description: data.description,
        userId: {
          id: deserventEdit.userId.id,
          cnic: data.cnic,
          password: data.password,
          mobile: data.mobile,
          email: data.email,
          name: data.name,
          address: data.address,
          location: data.locationName,
          // roles: {},
          isAnonymous: isAnonymous,
          // profileImage: data.profileImage,
        },
        familyMember: data.familyMember,
        priority: data.priority.name,
        occupation: data.occupation,
        referredBy: { id: data.referredBy.id },
        zakatApplicable: data.zakatApplicable.name,
      };
      addDeservants(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
          setIsAnonymous(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Deservants Updated Successfully",
            life: 5000,
          });
        }
      });
    } else {
      let dataForApi = {
        adultMember: data.adultMember,
        dependentMember: data.dependentMember,
        description: data.description,
        userId: {
          cnic: data.cnic,
          password: data.password,
          mobile: data.mobile,
          email: data.email,
          name: data.name,
          address: data.address,
          location: data.locationName,
          // roles: {},
          isAnonymous: isAnonymous,
        },
        familyMember: data.familyMember,
        priority: data.priority.name,
        occupation: data.occupation,
        referredBy: { id: data.referredBy.id },
        zakatApplicable: data.zakatApplicable.name,
      };
      addDeservants(dataForApi).then((res) => {
        if (res.status === 200) {
          reset();
          hideDialog();
          setIsAnonymous(false);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Deservants Updated Successfully",
            life: 5000,
          });
        }
      });
    }
  };
  const openNew = () => {
    reset();

    setIsAnonymous(false);
    setId(null);
    setsidebarVisible(true);

    setValuesForEdit(null);
  };
  const confirmDeleteSelected = (id) => {
    setDeleteDeservantDialog(true);
    setDeleteDeservant(id);
  };
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const deleteSelectedDeservants = () => {
    deleteDeservants(deleteDeservant).then((response) => {
      if (response.status === 200) {
        filteredDeservants({});
        setDeleteDeservantDialog(false);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Deservant Deleted",
          life: 3000,
        });
      } else {
        setDeleteDeservantDialog(false);
        reset();
      }
    });
  };

  const onPageChange = (event) => {
    setBasicFirst(event.first);
    setCurrentPage(event.page);
    setPageSize(event.rows);
  };

  const priorityList = [
    { id: 1, name: "High", code: "H" },
    { id: 2, name: "Medium", code: "M" },
    { id: 3, name: "Low", code: "L" },
  ];

  const zakatApplicable = [{ name: "yes" }, { name: "no" }];
  // const locationList = [{ id:1, name: "Rawalpindi" }, { id:2, name: "Vehari" }];

  const fetchDistributors = () => {
    allDistributorsList().then((res) => {
      if (res.status === 200) {
        setDistributorsList(res.data);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    filteredDeservants({});
    fetchDistributors();
    loadAreaData();
  }, []);

  const leftContents = (
    <React.Fragment>
      <h5 className="mx-0 my-1">Deservants</h5>
    </React.Fragment>
  );
  const rightContents = (
    <React.Fragment>
      <span className="p-input-icon-left del-con">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Search
            className="w-20rem"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search "
          />
        </span>
        {props.type !== "dashboard" ? (
          <Button
            label="New"
            icon="pi pi-plus"
            style={{ marginLeft: "1rem" }}
            onClick={openNew}
          />
        ) : (
          ""
        )}
      </span>
    </React.Fragment>
  );

  useEffect(() => {
    priorityList.filter((type) => {
      return type.name === deserventEdit.priorityList;
    });
    if (deserventEdit) {
      setValue("location", deserventEdit.userId.locationName);
      setValue("userId", deserventEdit.userId.id);
      setValue("referredBy", deserventEdit.referredBy);
      setValue("address", deserventEdit.userId.address);
      setValue("adultMember", deserventEdit.adultMember);
      setValue("cnic", deserventEdit.userId.cnic);
      setValue("mobile", deserventEdit.userId.mobile);

      setValue(
        "creationDate",
        new Date(moment(deserventEdit.creationDate).format())
      );
      setValue(
        "priority",
        priorityList.filter((type) => type.name === deserventEdit.priority)[0]
      );

      setValue("dependentMember", deserventEdit.dependentMember);

      setValue("deservantId", deserventEdit.id);
      setValue("familyMember", deserventEdit.familyMember);
      setValue("description", deserventEdit.description);
      setValue("occupation", deserventEdit.occupation);
      setValue(
        "zakatApplicable",
        zakatApplicable.filter(
          (type) => type.name === deserventEdit.zakatApplicable
        )[0]
      );
      setValue("name", deserventEdit.userId.name);
      setValue("email", deserventEdit.userId.email);
      setIsAnonymous(deserventEdit.userId.isAnonymous);
    }
  }, [deserventEdit]);

  const setValuesInForm = (field) => {
    setDeserventEdit(field);
    setValue("userId", field.userId.id);
    setValue("referredBy", field.referredBy);
    setValue("address", field.address);
    setValue("adultMember", field.adultMember);
    setValue("location", field.userId.location);
    setValue("cnic", field.cnic);
    setValue("mobile", field.mobile);
    setValue("creationDate", new Date(moment(field.creationDate)));
    setValue("dependentMember", field.dependentMember);
    setValue("deservantId", field.id);
    setValue("familyMember", field.familyMember);
    setValue("description", field.description);
    setValue("occupation", field.occupation);
    setValue("zakatApplicable", field.zakatApplicable);
    setValue("name", field.userId.name);
    setValue("email", field.userId.email);
    setValue("priority", field.userId.priority);
  };
  const hideDeleteProductsDialog = () => {
    setDeleteDeservantDialog(false);
    setDeleteDeservant("");
  };
  const deleteDeservantDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedDeservants}
      />
    </React.Fragment>
  );

  const hideDialog = () => {
    filteredDeservants({});
    setValuesForEdit(null);
    setIsEdit(false);
    setsidebarVisible(false);
  };
  const hideDeservantDialog = () => {
    setDeservantDialoge(false);
  };
  const representativeBodyTemplate = (rowData) => {
    var imgpath = "data:image/jpg;base64, " + rowData.userId?.profileImage;
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-2">
          <div>
            {rowData.userId?.profileImage ? (
              <Avatar
                className="mr-2"
                size="large"
                shape="circle"
                image={imgpath}
              />
            ) : (
              <Avatar
                label={rowData?.userId?.name?.charAt(0).toUpperCase()}
                className="mr-2"
                size="large"
                shape="circle"
              />
            )}
          </div>
          <div>
            <p>{rowData?.userId?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const referredByBody = (option) => {
    return (
      <div className="p-multiselect-referredBy.userId.name-option">
        <span className="image-text">{option?.referredBy?.userId?.name}</span>
      </div>
    );
  };

  const representativeMultiSelectBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="flex align-items-center m-0 gap-0">
          <div>
            <Avatar
              label={rowData?.name?.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
            />
          </div>
          <div>
            <p>{rowData?.name}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const customBase64Uploader = (event) => {
    const file = event.files[0];
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(deserventEdit.id, formData).then((res) => {
      if (res.status === 200) {
        hideDialog();
        setDeserventEdit(false);
        reset();
      }
    });
  };

  const rowClick = (data) => {
    setSelectedDeservant(data);
    setDeservantDialoge(true);
  };

  const filteredDeservants = (filter) => {
    getDeservantFilter(filter, currentPage, pageSize).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        setDeservantAreaData(response.data.deservant);
        setTotalDeservants(response.data.count);
      } else {
        // show error
      }
    });
  };

  useEffect(() => {
    var filter = {};
    setLoading(true);
    if (refferedByFilter?.length > 0) {
      filter["referredBy"] = refferedByFilter.map((referrer) => referrer?.name);
    }
    if (selectedZakat.length > 0) {
      filter["zakatApplicable"] = selectedZakat;
    }
    if (searchedValue) {
      filter["search"] = searchedValue;
    }
    filteredDeservants(filter);
  }, [
    currentPage,
    pageSize,
    field,
    order,
    refferedByFilter,
    selectedZakat,
    searchedValue,
  ]);

  const representativeFilterTemplate = (options) => {
    return (
      // <div className="flex justify-content-center">
      <MultiSelect
        value={options.value}
        onChange={(e) => {
          setRefferedByFilter(e.value);
          options.filterCallback(e?.value);
        }}
        options={referrers}
        itemTemplate={representativeMultiSelectBodyTemplate}
        optionLabel="name"
        placeholder="Select Any"
        maxSelectedLabels={1}
        className="p-column-filter"
        // className="w-full md:w-18rem"
        style={{ minWidth: "19rem" }}
      />

      // </div>
    );
  };
  const zakatFilter = (options) => {
    return (
      <MultiSelect
        value={options.value}
        onChange={(e) => {
          setSelectedZakat(e?.value);
          options.filterCallback(e?.value);
        }}
        options={zakatApplicabls}
        placeholder="Select Any"
        maxSelectedLabels={1}
        // className="w-full md:w-12rem"
        className="p-column-filter"
        style={{ minWidth: "13.1rem" }}
      />
    );
  };
  return (
    <div className="layout-main">
      <div className="toolbar-radius-remover">
        <Toolbar
          style={{ border: "hidden" }}
          left={leftContents}
          right={rightContents}
        />
      </div>
      <div className="datatable-doc-demo">
        <Toast ref={toast} position="top-center" />
        <div className="card">
          <DataTable
            onRowClick={(e) => rowClick(e.data)}
            ref={dt}
            value={deservantData}
            dataKey="id"
            scrollable
            globalFilterFields={[
              "userId.name",
              "userId.address",
              "userId.cnic",
              "occupation",
              "referredBy.userId.name",
              "priority",
              "userId.location",
              "zakatApplicable",
              "userId.mobile",
              "familyMember",
              "adultMember",
              "dependentMember",
              "description",
            ]}
            emptyMessage="No deservants found."
            responsiveLayout="scroll"
            loading={loading}
            className="table-sel mytab"
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            onSort={(e) => {
              e?.multiSortMeta[0]?.order === 1
                ? setOrder("asc")
                : setOrder("desc");
              setMultiSortMeta(e.multiSortMeta);
              setField(e?.multiSortMeta[0]?.field);
            }}
          >
            <Column
              field="userId.name"
              header="Name"
              body={representativeBodyTemplate}
              style={{ minWidth: "14rem" }}
              sortable
              sortField="userId.name"
            />

            <Column
              filterField="referredBy"
              header="Referred By"
              style={{ minWidth: "14rem" }}
              sortable
              sortField="referredBy"
              showFilterMatchModes={false}
              body={referredByBody}
              filter
              filterElement={representativeFilterTemplate}
              // filterMenuStyle={{ width: '14rem' }}
              showFilterOperator={false}
              showAddButton={false}
              filterApply
              filterClear
            />

            <Column
              field="userId.location.locationName"
              header="Location"
              style={{ minWidth: "14rem" }}
              sortable
              sortField="location"
              filterPlaceholder="Search by location"
            />

            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                className="traininghover"
                field="Training"
                header="Training"
                body={(record) => {
                  return (
                    <div>
                      <Tag
                        style={{
                          fontSize: "100%",
                          backgroundColor: "#00B1FF",
                          marginTop: "1px",
                        }}
                        className="mr-2"
                      >
                        Attended (
                        <b>
                          {record.attendence}/{record?.invited}
                        </b>
                        )
                      </Tag>
                    </div>
                  );
                }}
                style={{ minWidth: "12rem" }}
              />
            )}

            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="userId.address"
                header="Address"
                body={(record) => {
                  return record.userId.address;
                }}
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by userId.address"
              />
            )}

            <Column
              field="userId.mobile"
              header="Mobile"
              body={(record) => {
                return record.userId.mobile;
              }}
              style={{ minWidth: "14rem" }}
              filterPlaceholder="Search by userId.mobile"
            />
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="priority"
                header="Priority"
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by priority"
              />
            )}
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              //   <Column
              //     field="userId.location"
              //     header="Location"
              //     style={{ minWidth: "14rem" }}
              //     filterPlaceholder="Search by location"
              //   />
              // )}
              // {props.type && props.type === "dashboard" ? (
              //   []
              // ) : (
              <Column
                field="occupation"
                header="Occupation"
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by occupation"
              />
            )}
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="userId.cnic"
                header="Cnic"
                body={(record) => {
                  return record.userId.cnic;
                }}
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by userId.cnic"
              />
            )}
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="zakatApplicable"
                header="Zakat Applicable"
                style={{ minWidth: "14rem" }}
                showFilterMatchModes={false}
                filterPlaceholder="Search by zakatApplicable"
                filter
                filterElement={zakatFilter}
                showFilterOperator={false}
                showAddButton={false}
                filterApply
                filterClear
              />
            )}

            <Column
              field="familyMember"
              header="Family Members"
              style={{ minWidth: "14rem" }}
              filterPlaceholder="Search by familyMember"
            />
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="adultMember"
                header="Adult Members"
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by adultMember"
              />
            )}
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="dependentMember"
                header="Dependent Member"
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by dependentMember"
              />
            )}
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                field="description"
                header="Decription"
                style={{ minWidth: "14rem" }}
                filterPlaceholder="Search by description"
              />
            )}
            {props.type && props.type === "dashboard" ? (
              []
            ) : (
              <Column
                header="Action"
                fixed="Right"
                frozen
                alignFrozen="right"
                field={(field) => (
                  <div className="edit-delete-btn">
                    <Button
                      icon="pi pi-pencil"
                      className="p-button-rounded p-button-outlined"
                      onClick={() => {
                        setsidebarVisible(true);
                        setIsEdit(true);
                        setValuesInForm(field);
                      }}
                    />
                    <Button
                      icon="pi pi-trash"
                      className="p-button-rounded red-color-btn p-button-spacing"
                      onClick={() => confirmDeleteSelected(field.id)}
                    />
                  </div>
                )}
              ></Column>
            )}
          </DataTable>

          <Sidebar
            visible={sidebarVisible}
            position="right"
            style={{ width: "60em" }}
            onHide={() => {
              setsidebarVisible(false);
              setValuesForEdit(null);
              setIsEdit(false);
            }}
          >
            <h5
              className="text-center font-for-register"
              style={{ marginTop: "-7px" }}
            >
              Deservants
            </h5>
            <TabView>
              <TabPanel header="Personal Info" style={{ marginTop: "1px" }}>
                <div className="flex justify-content-center">
                  <div className="card width-adjuster-for-add-deservant-form">
                    <form className="p-fluid" onSubmit={handleSubmit(OnSubmit)}>
                      <div className="p-fluid">
                        <div
                          className="field grid"
                          style={{ marginTop: "3px" }}
                        >
                          <label
                            htmlFor="name"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Name</b>
                            <span className="color-for-str">*</span>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="name"
                              control={control}
                              rules={{ required: "name is required." }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage("name")}
                          </div>
                        </div>
                        <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="referredBy"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Referred By</b>{" "}
                            <span className="color-for-str">*</span>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="referredBy"
                              control={control}
                              rules={{
                                required: "you need to choose a referrer.",
                              }}
                              render={({ field }) => (
                                <Dropdown
                                  id={field.name}
                                  {...field}
                                  autoFocus
                                  options={distributorsList}
                                  optionLabel={(option) => {
                                    return option.userId.name;
                                  }}
                                />
                              )}
                            />
                            {getFormErrorMessage("referredBy")}
                          </div>
                        </div>
                        <div
                          className="field grid"
                          style={{ marginTop: "30px" }}
                        >
                          <label
                            htmlFor="location"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b> Location </b>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="locationName"
                              control={control}
                              // rules={{ required: "you need to choose a referre." }}
                              render={({ field, fieldState }) => (
                                <Dropdown
                                  id={field.name}
                                  {...field}
                                  autoFocus
                                  options={locationTable}
                                  optionLabel={(option) => {
                                    return option.locationName;
                                  }}
                                />
                              )}
                            />
                            {/* {getFormErrorMessage("referredBy")} */}
                          </div>
                        </div>
                        <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="mobile"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Mobile</b>
                            <span className="color-for-str">*</span>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="mobile"
                              control={control}
                              rules={{
                                required: "Mobile is required.",
                                pattern: new RegExp(/^[0-9]{11}$/),
                              }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage("mobile")}
                          </div>
                        </div>

                        {/* <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="location"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Location</b>
                            <span className="color-for-str">*</span>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="location"
                              control={control}
                              rules={{
                                required: "you need to choose location.",
                              }}
                              render={({ field, fieldState }) => (
                                <Dropdown
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                                  id={field.name}
                                  {...field}
                                  autoFocus
                                  options={locationList}
                                  optionLabel={(option) => {
                                    return option.name;
                                  }}
                                  placeholder="Select location"

                                />
                              )}
                            />
                            {getFormErrorMessage("Select Location")}
                          </div>
                        </div> */}

                        <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="priority"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Priority</b>
                            <span className="color-for-str">*</span>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="priority"
                              control={control}
                              rules={{
                                required: "you need to choose priority.",
                              }}
                              render={({ field }) => (
                                <Dropdown
                                  id={field.name}
                                  {...field}
                                  autoFocus
                                  options={priorityList}
                                  optionLabel={(option) => {
                                    return option.name;
                                  }}
                                />
                              )}
                            />
                            {getFormErrorMessage("priority")}
                          </div>
                        </div>

                        <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="zakatApplicable"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Zakat Applicable</b>
                            <span className="color-for-str">*</span>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="zakatApplicable"
                              control={control}
                              rules={{
                                required: "Zakat Applicable is required.",
                              }}
                              render={({ field }) => (
                                <Dropdown
                                  id={field.name}
                                  {...field}
                                  autoFocus
                                  options={zakatApplicable}
                                  optionLabel={(option) => option.name}
                                  placeholder="Select Type"
                                />
                              )}
                            />
                            {getFormErrorMessage("zakatApplicable")}
                          </div>
                        </div>
                        <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="Email"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>Email</b>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="email"
                              control={control}
                              rules={{
                                pattern: {
                                  value: new RegExp(
                                    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                  ),
                                  message:
                                    "Invalid email address. E.g. example@email.com",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <InputText
                                    // type={"email"}
                                    keyfilter="alphanum"
                                    placeholder=" E.g. example@email.com"
                                    className={classNames({
                                      "p-invalid": fieldState.invalid,
                                    })}
                                    id={field.name}
                                    {...field}
                                  />
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                        </div>

                        <div
                          className="field grid"
                          style={{ marginTop: "1px" }}
                        >
                          <label
                            htmlFor="cnic"
                            className="col-12 mb-2 md:col-3 md:mb-0"
                          >
                            <b>CNIC</b>
                          </label>
                          <div className="col-10 md:col-8">
                            <Controller
                              name="cnic"
                              control={control}
                              rules={{
                                pattern: {
                                  value: RegExp(/^[0-9]{5}-[0-9]{7}-[0-9]$/),
                                  message:
                                    "Please enter correct format of CNIC",
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <InputText
                                  keyfilter="alphanum"
                                  placeholder="Enter valid CNIC format "
                                  className={classNames({
                                    "p-invalid": fieldState.invalid,
                                  })}
                                  id={field.name}
                                  {...field}
                                />
                              )}
                            />
                            {getFormErrorMessage("cnic")}
                          </div>
                        </div>

                        <div
                          className="field-checkbox"
                          style={{ marginTop: "4px" }}
                        >
                          <label htmlFor="binary">
                            <b>Anonymous</b>
                          </label>
                          <Checkbox
                            style={{ marginLeft: "15%", marginTop: "4x" }}
                            inputId="binary"
                            checked={isAnonymous}
                            onChange={(e) => setIsAnonymous(e.checked)}
                          />
                        </div>
                        {isEdit ? (
                          <div
                            className="field grid"
                            style={{ marginTop: "5px" }}
                          >
                            <label
                              htmlFor="profileImage"
                              className="col-12 mb-2 md:col-3 md:mb-0"
                            >
                              <b>Profile Image</b>
                            </label>
                            <div className="col-10 md:col-8">
                              <Controller
                                control={control}
                                name="demo"
                                render={() => (
                                  <FileUpload
                                    mode="basic"
                                    name="file"
                                    accept="image/*"
                                    maxFileSize={1000000}
                                    customUpload
                                    uploadHandler={customBase64Uploader}
                                    chooseLabel="Browse"
                                    auto
                                  />
                                )}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <Button
                        type="submit"
                        label="Submit"
                        className="submit-btn"
                        style={{ marginTop: "-9px" }}
                      />
                    </form>
                    <Button
                      type="cancel"
                      label="Cancel"
                      className="p-button-danger cancel-btn"
                      style={{ marginTop: "-9px" }}
                      onClick={hideDialog}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Family Info" style={{ marginTop: "1px" }}>
                <div className="p-fluid">
                  <div className="field grid" style={{ marginTop: "3px" }}>
                    <label
                      htmlFor="occupation"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Occupation</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="occupation"
                        control={control}
                        // rules={{ required: "Occupation is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("occupation")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "1px" }}>
                    <label
                      htmlFor="familyMember"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Family Member</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="familyMember"
                        control={control}
                        // rules={{
                        //   pattern: {value: new RegExp(/^[0-9]$/) , message: "Please enter numeric value."}

                        // }}
                        render={({ field, fieldState }) => (
                          <>
                            <InputText
                              keyfilter="int"
                              placeholder="Enter Family Member Count"
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              id={field.name}
                              {...field}
                            />
                            {getFormErrorMessage(field.name)}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "1px" }}>
                    <label
                      htmlFor="adultMember"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Adult Member</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="adultMember"
                        control={control}
                        // rules={{
                        //   pattern: {value: new RegExp(/^[0-9]$/) , message: "Please enter numeric value."}

                        // }}
                        render={({ field, fieldState }) => (
                          <InputText
                            keyfilter="int"
                            placeholder="Enter Enter Adult Member Count"
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("adultMember")}
                    </div>
                  </div>
                  <div className="field grid" style={{ marginTop: "1px" }}>
                    <label
                      htmlFor="dependentMember"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Dependent Member</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="dependentMember"
                        control={control}
                        // rules={{
                        //   pattern: {value: new RegExp(/^[0-9]$/) , message: "Please enter numeric value."}

                        // }}

                        render={({ field, fieldState }) => (
                          <InputText
                            keyfilter="int"
                            placeholder="Enter Dependent Member Count"
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("dependentMember")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "1px" }}>
                    <label
                      htmlFor="address"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Address</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="address"
                        control={control}
                        // rules={{ required: "Address is required." }}
                        render={({ field, fieldState }) => (
                          <InputText
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("address")}
                    </div>
                  </div>

                  <div className="field grid" style={{ marginTop: "1px" }}>
                    <label
                      htmlFor="description"
                      className="col-12 mb-2 md:col-3 md:mb-0"
                    >
                      <b>Description</b>
                    </label>
                    <div className="col-10 md:col-8">
                      <Controller
                        name="description"
                        control={control}
                        // rules={{ required: "description is required." }}
                        render={({ field, fieldState }) => (
                          <InputTextarea
                            rows={5}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            id={field.name}
                            {...field}
                          />
                        )}
                      />
                      {getFormErrorMessage("description")}
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </Sidebar>
        </div>

        <Dialog
          visible={deleteDeservantDialog}
          style={{ width: "450px" }}
          header="Confirm"
          modal
          footer={deleteDeservantDialogFooter}
          onHide={hideDeleteProductsDialog}
        >
          <div className="confirmation-content">
            <i
              className="pi pi-exclamation-triangle mr-3"
              style={{ fontSize: "2rem" }}
            />
            {
              <span>
                Are you sure you want to delete the selected deservants?
              </span>
            }
          </div>
        </Dialog>
        <Dialog
          visible={deservantDialoge}
          style={{ width: "800px" }}
          header={selectedDeservant?.userId?.name}
          // modal
          // footer={deleteItemDialogFooter}
          onHide={hideDeservantDialog}
        >
          <DeservantDetails deservant={selectedDeservant} />
        </Dialog>
      </div>
      <Paginator
        first={basicFirst}
        rows={pageSize}
        totalRecords={totalDeservants}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
        template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Deservants"
      ></Paginator>
    </div>
  );
};
export default Deservant;
