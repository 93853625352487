import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  message,
  notification,
} from "antd";
import {
  ALPHABETICAL_INPUTS,
  MOBILE_NUMBER,
  NUMERIC_INPUTS,
} from "../../../utils/constants";
import { addUser, getRoles, getUsers } from "../../../apis/SettingApis";
import { useEffect, useState } from "react";
import { getAllAreas } from "../../../apis/AreaApi";
import TextArea from "antd/es/input/TextArea";
import { allDistributorsList } from "../../../apis/DistributorApi";
import { getAllLocation } from "../../../apis/LocationApi";
import { useForm } from "react-hook-form";

const UserForm = (props) => {
  const [form] = Form.useForm();
  const [rolesOptions, setRolesOptions] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [loading, setLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [userTypes, setUserTypes] = useState({
    donor: false,
    distributor: false,
    deservant: false,
    lecturer: false,
  });
  const [isEdit, setIsEdit] = useState(props.editMode);
  useEffect(() => {
    setIsEdit(props.editMode);
  }, [props.editMode]);

  const handleCheckboxChange = (type, checked) => {
    setUserTypes((prev) => ({ ...prev, [type]: checked }));
  };

  const { Option } = Select;

  const {
    formState: { errors },
    reset,
  } = useForm();

  const onEditFunction = () => {
    if (props.editorValues && props.editMode) {
      form.setFieldsValue({
        name: props.editorValues?.user?.name,
        email: props.editorValues?.user?.email,
        mobile: props.editorValues?.user?.mobile,
        cnic: props.editorValues?.user?.cnic,
        address: props.editorValues?.user?.address,
        location: props.editorValues?.user?.location?.id,

        donorReferredBy: props.editorValues.donorCheckBox
          ? props.editorValues?.donor?.referredBy?.id
          : null,
        areaId: props.editorValues.distributorCheckBox
          ? props.editorValues?.distributors?.areaId?.id
          : null,
        deservantReferredBy: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.referredBy?.id
          : null,
        distributor: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants.distributor?.userId.id
          : null,
        priority: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.priority
          : null,
        zakatApplicable: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.zakatApplicable
          : null,
        occupation: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.occupation
          : null,
        familyMembers: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.familyMember
          : null,
        adultMembers: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.adultMember
          : null,
        dependentMembers: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.dependentMember
          : null,
        description: props.editorValues.deservantCheckBox
          ? props.editorValues?.deservants?.description
          : null,
        skill: props.editorValues.lecturerCheckBox
          ? props.editorValues?.lecturer?.skill
          : null,
      });
      setIsAnonymous(props.editorValues?.user?.isAnonymous ? props.editorValues?.user?.isAnonymous : false)
      setUserTypes({
        donor: props.editorValues?.donorCheckBox,
        distributor: props.editorValues?.distributorCheckBox,
        deservant: props.editorValues?.deservantCheckBox,
        lecturer: props.editorValues?.lecturerCheckBox,
      });
    } else {
      form.resetFields();
      setUserTypes({
        donor: false,
        distributor: false,
        deservant: false,
        lecturer: false,
      });
    }
  };

  const fetchUsers = async () => {
    const response = await getUsers();
    if (response.status === 200) {
      setUsers(response.data.sort((user1, user2) => user1.id - user2.id));
      setFilteredUsers(response.data);
    } else {
    }
  };

  const fetchRoles = async () => {
    const response = await getRoles();
    if (response.status === 200) {
      setRolesOptions(response.data);
    }
  };

  const fetchAreaList = () => {
    getAllAreas().then((res) => {
      if (res.status === 200) {
        setAreaList(res.data);
      }
    });
  };

  const fetchDistributors = async () => {
    const response = await allDistributorsList();
    if (response.status === 200) {
      setDistributors(response.data);
    }
  };

  const fetchLocations = async () => {
    const response = await getAllLocation();
    if (response.status === 200) {
      setLocations(response.data);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchUsers();
    fetchAreaList();
    fetchLocations();
    fetchDistributors();
    onEditFunction();
  }, [props?.editorValues, props.editMode]);

  const handleSubmit = () => {
    setTimeout(() => {
      fetchUsers();
      fetchDistributors();
    }, 5000);
  };

  const priorityList = [
    { id: "High", name: "High", code: "H" },
    { id: "Medium", name: "Medium", code: "M" },
    { id: "Low", name: "Low", code: "L" },
  ];

  const zakatApplicable = [{ name: "yes" }, { name: "no" }];

  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 100000);
  };

  const onSubmit = async (data) => {
    if (
      !userTypes.donor &&
      !userTypes.distributor &&
      !userTypes.deservant &&
      !userTypes.lecturer
    ) {
      return;
    }
    if ((data.mobile == null || data.mobile == "") && userTypes.donor && data.donorReferredBy == null) {
      message.error("Please add a Refferer.");
      return;
    }
    if ((userTypes.distributor || userTypes.deservant || userTypes.lecturer) && (data.mobile == null || data.mobile == "")) {
      message.error("Please add Mobile.");
      return;
    }

    if (props.editMode) {
      const dataForApi = {
        user: {
          id: props.editorValues.user.id,
          name: data.name,
          email: data.email,
          username: data.name + generateRandomNumber(),
          mobile: data.mobile,
          cnic: data.cnic,
          address: data.address,
          location: { id: data.location },
          isAnonymous: isAnonymous,
        },
        donorCheckBox: userTypes.donor,
        distributorCheckBox: userTypes.distributor,
        deservantCheckBox: userTypes.deservant,
        lecturerCheckBox: userTypes.lecturer,

        ...(userTypes.donor &&
          props.editorValues.donor && {
          donor: {
            id: props.editorValues.donor.id,
            referredBy: { id: data.donorReferredBy },
            userId: { id: props.editorValues.user.id },
          },
        }),

        ...(userTypes.donor &&
          !props.editorValues.donor && {
          donor: {
            referredBy: { id: data.donorReferredBy },
          },
        }),

        ...(userTypes.distributor &&
          props.editorValues.distributors && {
          distributors: {
            id: props.editorValues.distributors.id,
            areaId: { id: data.areaId },
            userId: { id: props.editorValues.user.id },
          },
        }),

        ...(userTypes.distributor &&
          !props.editorValues.distributors && {
          distributors: {
            areaId: { id: data.areaId },
          },
        }),

        ...(userTypes.deservant &&
          props.editorValues.deservants && {
          deservants: {
            id: props.editorValues.deservants.id,
            referredBy: { id: data.deservantReferredBy },
            distributor: { id: data.distributor },
            priority: data.priority,
            zakatApplicable: data.zakatApplicable,
            occupation: data.occupation,
            familyMember: data.familyMembers,
            adultMember: data.adultMembers,
            dependentMember: data.dependentMembers,
            description: data.description,
            userId: { id: props.editorValues.user.id },
          },
        }),

        ...(userTypes.deservant &&
          !props.editorValues.deservants && {
          deservants: {
            referredBy: { id: data.deservantReferredBy },
            distributor: { id: data.distributor },
            priority: data.priority,
            zakatApplicable: data.zakatApplicable,
            occupation: data.occupation,
            familyMember: data.familyMembers,
            adultMember: data.adultMembers,
            dependentMember: data.dependentMembers,
            description: data.description,
          },
        }),

        ...(userTypes.lecturer &&
          props.editorValues.lecturer && {
          lecturer: {
            id: props.editorValues.lecturer.id,
            skill: data.skill,
            userId: { id: props.editorValues.user.id },
          },
        }),

        ...(userTypes.lecturer &&
          !props.editorValues.lecturer && {
          lecturer: {
            skill: data.skill,
          },
        }),
      };

      setLoading(true);
      addUser(dataForApi)
        .then((res) => {
          if (res.status === 200) {
            openNotification(
              "bottomRight",
              "success",
              "User Updated",
              "User has been updated successfully"
            );
            props.onSubmitSuccess();
          } else {
            openNotification(
              "bottomRight",
              "error",
              "User Updation Failed",
              "Could not update user ! Please try again."
            );
            props.hide();
          }
        })
        .finally(() => {
          setLoading(false);
          props.hide();
        });
    } else {
      const dataForApi = {
        user: {
          name: data.name,
          email: data.email,
          username: data.name + generateRandomNumber(),
          mobile: data.mobile,
          cnic: data.cnic,
          address: data.address,
          location: { id: data.location },
          isAnonymous: isAnonymous,
        },
        donorCheckBox: userTypes.donor,
        distributorCheckBox: userTypes.distributor,
        deservantCheckBox: userTypes.deservant,
        lecturerCheckBox: userTypes.lecturer,

        ...(userTypes.donor && {
          donor: { referredBy: { id: data.donorReferredBy } },
        }),

        ...(userTypes.distributor && {
          distributors: { areaId: { id: data.areaId } },
        }),

        ...(userTypes.deservant && {
          deservants: {
            referredBy: { id: data.deservantReferredBy },
            distributor: { id: data.distributor },
            priority: data.priority,
            zakatApplicable: data.zakatApplicable,
            occupation: data.occupation,
            familyMember: data.familyMembers,
            adultMember: data.adultMembers,
            dependentMember: data.dependentMembers,
            description: data.description,
            status: "VERIFIED",
          },
        }),

        ...(userTypes.lecturer && { lecturer: { skill: data.skill } }),
      };

      setLoading(true);
      addUser(dataForApi)
        .then((res) => {
          if (res.status === 200) {
            openNotification(
              "bottomRight",
              "success",
              "User Added",
              "User has been added successfully"
            );
            props.onSubmitSuccess();
          } else {
            openNotification(
              "bottomRight",
              "error",
              "User Addition Failed",
              "Could not add user ! Please try again."
            );
            props.hide();
          }
        })
        .finally(() => {
          setLoading(false);
          props.hide();
        });

      form.resetFields();
      userTypes.donor = false;
      userTypes.distributor = false;
      userTypes.deservant = false;
      userTypes.lecturer = false;
    }
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, type, heading, message) => {
    notification[type]({
      message: heading,
      description: message,
      placement,
    });
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <h5 className="text-center font-semibold text-5xl">User Info</h5>

          <Col span={25}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  pattern: ALPHABETICAL_INPUTS,
                  type: "string",
                  message: "Please enter valid name",
                },
              ]}
            >
              <Input maxLength={512} />
            </Form.Item>
          </Col>

          <Col span={25}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: false,
                  type: "email",
                  message: "Please enter valid email",
                },
              ]}
            >
              <Input type="email" maxLength={64} />
            </Form.Item>
          </Col>

          <Col span={25}>
            <Form.Item
              name="mobile"
              label="Mobile"
              rules={[
                {
                  pattern: MOBILE_NUMBER,
                  type: "string",
                  message: "Please enter valid mobile no",
                },
              ]}
            >
              <Input
                placeholder="+92xxxxxxxxxx"
                maxLength={15}
                minLength={10}
              />
            </Form.Item>
          </Col>

          <Col span={25}>
            <Form.Item
              name="cnic"
              label="CNIC"
              rules={[
                {
                  required: false,
                  type: "string",
                  message: "Please enter valid cnic",
                },
              ]}
            >
              <Input
                placeholder="xxxxx-xxxxxxx-x"
                maxLength={15}
                minLength={15}
              />
            </Form.Item>
          </Col>
          <Col span={25}>
            <Form.Item
              name="address"
              label="Address"
              rules={[
                {
                  required: false,
                  type: "string",
                  message: "Please enter valid address",
                },
              ]}
            >
              <Input maxLength={512} />
            </Form.Item>
          </Col>

          <Col span={25}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Please select location",
                },
              ]}
            >
              <Select placeholder="Select/Enter location">
                {locations.map((location) => (
                  <Option value={location.id}>{location.locationName}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={25}>
            <Checkbox
              checked={isAnonymous}
              onChange={(e) => setIsAnonymous(e.target.checked)}
            >
              Anonymous
            </Checkbox>
          </Col>

          <h6 className="text-center font-semibold text-4xl mt-4 mb-4">
            User Role
          </h6>

          <Form.Item
            name="userRoles"
            validateStatus={
              userTypes.donor ||
                userTypes.distributor ||
                userTypes.deservant ||
                userTypes.lecturer
                ? ""
                : "error"
            }
            help={
              userTypes.donor ||
                userTypes.distributor ||
                userTypes.deservant ||
                userTypes.lecturer
                ? ""
                : "Please select at least one role"
            }
          >
            <div className="flex">
              <Checkbox
                checked={userTypes.donor}
                onChange={(e) =>
                  handleCheckboxChange("donor", e.target.checked)
                }
                className="flex-1"
              >
                Donor
              </Checkbox>
              <Checkbox
                checked={userTypes.distributor}
                onChange={(e) =>
                  handleCheckboxChange("distributor", e.target.checked)
                }
                className="flex-1"
              >
                Distributor
              </Checkbox>
              <Checkbox
                checked={userTypes.deservant}
                onChange={(e) =>
                  handleCheckboxChange("deservant", e.target.checked)
                }
                className="flex-1"
              >
                Deservant
              </Checkbox>
              <Checkbox
                checked={userTypes.lecturer}
                onChange={(e) =>
                  handleCheckboxChange("lecturer", e.target.checked)
                }
                className="flex-1"
              >
                Lecturer
              </Checkbox>
            </div>
          </Form.Item>

          {userTypes.donor && (
            <div className="donorSection border-top-2 border-blue-400 my-6">
              <h6 className="text-center font-semibold text-4xl mt-3">
                Donor Info
              </h6>
              <Col span={25}>
                <Form.Item
                  name="donorReferredBy"
                  label="Referred By (User)"
                  rules={[
                    {
                      required: false,
                      message: "Please select referrer",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select referrer"
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                    allowClear
                  >
                    {filteredUsers.map((user) => (
                      <Option value={user?.id}>{user?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          )}

          {userTypes.distributor && (
            <div className="distributorSection border-top-2 border-blue-400 my-6">
              <h6 className="text-center font-semibold text-4xl mt-3">
                Distributor Info
              </h6>
              <Col span={25}>
                <Form.Item
                  name="areaId"
                  label="Area"
                  rules={[
                    {
                      required: true,
                      message: "Please select area",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select area"
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                    allowClear
                  >
                    {areaList.map((area) => (
                      <Option value={area?.id}>{area?.areaName}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </div>
          )}

          {userTypes.deservant && (
            <div className="deservantSection border-top-2 border-blue-400 my-6">
              <h6 className="text-center font-semibold text-4xl mt-3">
                Deservant Info
              </h6>
              <Col span={25}>
                <Form.Item
                  name="deservantReferredBy"
                  label="Referred By"
                  rules={[
                    {
                      required: true,
                      message: "Please select referrer",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select referrer"
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                    allowClear
                  >
                    {users.map((user) => (
                      <Option value={user?.id}>{user?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={25}>
                <Form.Item
                  name="distributor"
                  label="Distributor"
                  rules={[
                    {
                      required: true,
                      message: "Please select distributor",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select distributor"
                    showSearch
                    optionFilterProp="children"
                    filterOption={true}
                    allowClear
                  >
                    {distributors.map((distributor) => (
                      <Option value={distributor.userId.id}>
                        {distributor.userId?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={25}>
                <Form.Item
                  name="priority"
                  label="Priority"
                  rules={[
                    {
                      required: true,
                      message: "Please select priority",
                    },
                  ]}
                >
                  <Select placeholder="Select priority" allowClear>
                    {priorityList.map((area) => (
                      <Option value={area.id}>{area?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={25}>
                <Form.Item
                  name="zakatApplicable"
                  label="Zakat Applicable"
                  rules={[
                    {
                      required: false,
                      message: "Please select zakat applicable",
                    },
                  ]}
                >
                  <Select placeholder="Select zakat applicable" allowClear>
                    {zakatApplicable.map((area) => (
                      <Option value={area?.name}>{area?.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <h6 className="font-semibold text-3xl mt-3">Family Info</h6>

              <Col span={25}>
                <Form.Item
                  name="occupation"
                  label="Occupation"
                  rules={[
                    {
                      required: false,
                      message: "Please enter occupation",
                    },
                  ]}
                >
                  <Input placeholder="Enter occupation" maxLength={512} />
                </Form.Item>
              </Col>

              <Col span={25}>
                <Form.Item
                  name="familyMembers"
                  label="Family Members"
                  rules={[
                    {
                      required: false,
                      message: "Please enter family members",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Family Member Count"
                    maxLength={3}
                  />
                </Form.Item>
              </Col>

              <Col span={25}>
                <Form.Item
                  name="adultMembers"
                  label="Adult Members"
                  rules={[
                    {
                      required: false,
                      message: "Please enter adult members count",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Adult Member Count"
                    maxLength={3}
                  />
                </Form.Item>
              </Col>

              <Col span={25}>
                <Form.Item
                  name="dependentMembers"
                  label="Dependent Members"
                  rules={[
                    {
                      required: false,
                      message: "Please enter dependent members count",
                    },
                  ]}
                >
                  <InputNumber
                    className="w-full"
                    placeholder="Enter Dependent Member Count"
                    maxLength={3}
                  />
                </Form.Item>
              </Col>

              <Col span={25}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: false,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea placeholder="Enter description" maxLength={512} />
                </Form.Item>
              </Col>
            </div>
          )}

          {userTypes.lecturer && (
            <div className="lecturerSection  border-top-2 border-blue-400 my-6">
              <h6 className="text-center font-semibold text-4xl mt-3">
                Lecturer Info
              </h6>
              <Col span={25}>
                <Form.Item
                  name="skill"
                  label="Skill"
                  rules={[
                    {
                      required: false,
                      message: "Please enter skill",
                    },
                  ]}
                >
                  <Input placeholder="Enter skill" maxLength={512} />
                </Form.Item>
              </Col>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "15px",
            }}
          >
            <div>
              <Button
                className="ant-btn-adjuster"
                type="primary"
                key="submit"
                htmlType="submit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default UserForm;
